import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Box,Typography,Grid, FormControlLabel, TextField } from '@mui/material';
import { StoreContext } from "../../StoreContext";


type HeaderProps = {
    title: string;
  };

const OverseasPostalAddress = observer(({ title }: HeaderProps) => {
    const { onlineRedirectionStore } = useContext(StoreContext);    
    const {
      overseasAddressLine1, 
      overseasAddressLine2, 
      overseasAddressLine3, 
      overseasLocalitySuburb, 
      overseasPostZipCode, 
      overseasState, 
      country,
      isOverseasAddressLine1Valid,
      isCountryValid,
      showErrors,
      submitPostalAddressTypeId
      } = onlineRedirectionStore; 
            
      const handleOverseasAddressLine1Change = (e) => {onlineRedirectionStore.setOverseasAddressLine1(e.target.value);}
      const handleOverseasAddressLine2Change = (e) => {onlineRedirectionStore.setOverseasAddressLine2(e.target.value);}
      const handleOverseasAddressLine3Change = (e) => {onlineRedirectionStore.setOverseasAddressLine3(e.target.value);}
      const handleOverseasLocalitySuburbChange = (e) => {onlineRedirectionStore.setOverseasLocalitySuburb(e.target.value);}
      const handleOverseasPostZipCodeChange = (e) => {onlineRedirectionStore.setOverseasPostZipCode(e.target.value);}
      const handleOverseasStateChange = (e) => {onlineRedirectionStore.setOverseasState(e.target.value);}
      const handleCountryChange = (e) => {onlineRedirectionStore.setCountry(e.target.value);}     

      const isDirty = showErrors && submitPostalAddressTypeId == "2"; //Overseas
    
    return (
      <Box component="section">
        {/* <StyleRoot> */}        
        <Typography variant="h2">{title}</Typography>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Box sx={{ }}>              
            <FormControlLabel control = {
                                    <TextField
                                        id="txtOverseasAddressLine1"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Enter address line 1"
                                        required
                                        inputProps={{
                                            maxLength: 100
                                          }}
                                        value={overseasAddressLine1}
                                        onChange={handleOverseasAddressLine1Change}
                                        helperText={showErrors && isDirty && !isOverseasAddressLine1Valid ? "Please provide address line 1" : ""}
                                    />
                                } label={"Address line 1"} labelPlacement='top'/>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ }}>              
            <FormControlLabel control = {
                                    <TextField
                                        id="txtOverseasAddressLine2"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Enter address line 2"
                                        inputProps={{
                                            maxLength: 50
                                          }}
                                        value={overseasAddressLine2}
                                        onChange={handleOverseasAddressLine2Change}
                                    />
                                } label={"Address line 2"} labelPlacement='top'/>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{  }}>
            <FormControlLabel control = {
                                    <TextField
                                        id="txtOverseasAddressLine3"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Enter address line 3"
                                        inputProps={{
                                            maxLength: 50
                                          }}
                                        value={overseasAddressLine3}
                                        onChange={handleOverseasAddressLine3Change}
                                    />
                                } label={"Address line 3"}labelPlacement='top'/>
            </Box>
          </Grid>          
          <Grid item xs={12} sm={6}>
            <Box sx={{  }}>
            <FormControlLabel control = {
                                    <TextField
                                        id="txtOverseasLocalitySuburb"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Enter locality/suburb"
                                        inputProps={{
                                            maxLength: 60
                                          }}
                                        value={overseasLocalitySuburb}
                                        onChange={handleOverseasLocalitySuburbChange}

                                    />
                                } label={"Locality/suburb"} labelPlacement='top'/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ }}>
            <FormControlLabel control = {
                                    <TextField
                                        id="txtOverseasAddressPostcode"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Enter postcode/Zip code"
                                        inputProps={{
                                            maxLength: 10
                                          }}
                                        value={overseasPostZipCode}
                                        onChange={handleOverseasPostZipCodeChange}
                                    />
                                } label={"Postcode/zip code"} labelPlacement='top'/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{  }}>
            <FormControlLabel control = {
                                    <TextField
                                        id="txtOverseasAddressState"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Enter State"
                                        inputProps={{
                                            maxLength: 30
                                          }}
                                        value={overseasState}
                                        onChange={handleOverseasStateChange}
                                    />
                                } label={"State"} labelPlacement='top'/>                 
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{  }}>
            <FormControlLabel control = {
                                    <TextField
                                        id="txtOverseasAddressCountry"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="Enter Country"
                                        required
                                        inputProps={{
                                            maxLength: 60
                                          }}
                                        value={country}
                                        onChange={handleCountryChange}
                                        helperText={showErrors && isDirty && !isCountryValid ? "Please provide country" : ""}
                                    />
                                } label={"Country"} labelPlacement='top'/>                  
            </Box>
          </Grid> 
        </Grid>
        {/* </StyleRoot> */}
      </Box>
    );
  });
  
  export default OverseasPostalAddress;
