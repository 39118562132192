import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import { Padding } from '@mui/icons-material';

export default function CancelButton() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const gridItemStyle = {
    paddingTop:{ xs:'10px !important', md:'0px !important'},
    PaddingLeft:'25px !important',
    PaddingRight:'25px !important',
  };

  const dialogActionStyle = {
    paddingLeft: {xs: '10px !important', md: '80px !important', lg: '100px !important'},
    paddingRight: {xs: '10px !important', md: '80px !important', lg: '100px !important'}
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleYes = () => {
    window.location.reload();
  };

  const handleBackdropClick = (event) => {
    // Prevent closing the dialog when clicking on the backdrop
    event.stopPropagation();
  };

  return (
    <React.Fragment>
      <Button variant="outlined" onClick={handleClickOpen}>
        Clear
      </Button>
      <Dialog
        fullScreen={false}
        open={open}
        onClose={handleClose}
        sx={{'& .MuiBackdrop-root': { pointerEvents: 'none' }}}
        aria-labelledby="Clear the online redirection application form"
        onClick={handleBackdropClick}
      >
        <DialogTitle id="online-redirection-form-dialog-clear-button"
                      sx={{ fontSize: { xs: '1.4rem', md: '1.6rem' },color:'#0E3A6C' }} >
          {"Warning"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText variant='body2'>
            There are unsaved changes in the current screen. Do you want to discard these changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={dialogActionStyle}>
          <Grid container spacing={1} justifyContent="center" sx={{ paddingTop:'5px' }}>            
            <Grid item xs={12} md={6} sx={gridItemStyle}>
              <Button fullWidth variant='outlined' onClick={handleYes} autoFocus>
                Yes
              </Button>
            </Grid>
            <Grid item xs={12} md={6} sx={gridItemStyle}>
              <Button fullWidth variant='outlined' onClick={handleClose}>
                No
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
