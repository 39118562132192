import React from "react";
import * as ReactDOMClient from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import AppMain from "./components/ora/main-page";
import { StoreContext, stores } from "./StoreContext";
import VecTheme from "./components/vec-theme";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

class Root extends React.Component {
  componentDidMount() {
    
  }

  render() {
    return (
      <StoreContext.Provider value={stores}>
        <VecTheme>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <BrowserRouter>
              <AppMain />
            </BrowserRouter>
          </LocalizationProvider>          
        </VecTheme>
      </StoreContext.Provider>
    );
  }
};

const container = document.getElementById("root");
if (container) {
  const root = ReactDOMClient.createRoot(container);
  root.render(
    <React.StrictMode>
      <Root />
    </React.StrictMode>
  );
} else {
  alert(
    "Could not locate DOM element with id 'root' in which to place the React application."
  );
}
