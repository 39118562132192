import RootStore from "./root.store";
import OnlineRedirectionStore from "./ora/online.redirection.store";

export default class SessionStore {
  rootStore: RootStore;
  onlineRedirectionStore: OnlineRedirectionStore;

  constructor() {
    this.rootStore = new RootStore(); 
    this.onlineRedirectionStore = new OnlineRedirectionStore(this.rootStore);
  }
}
