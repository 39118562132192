import "whatwg-fetch";

declare global {
  interface Navigator {
    msSaveOrOpenBlob: (blob: Blob, fileName: string) => boolean;
  }
}

export const getResponseJson = async <TResponse>(
  response
): Promise<TResponse> => {
  if (!response.ok) {
    let error = await response.text();
    if (!error) {
      if (response.bodyUsed) {
        response
          .clone()
          .json()
          .then((content) => {
            error = content;
            return new Error(error);
          });
      } else {
        throw new Error("Failed");
      }
    } else {
      throw new Error(`${error} (Status code: ${response.status})`);
    }
  }
  return (await response.json()) as TResponse;
};

export const getResponseText = async (response): Promise<string> => {
  if (!response.ok) {
    const message = response.text();
    if (message !== undefined && message !== "") {
      return Promise.reject(new Error(message));
    }
    return Promise.reject(new Error("Failed"));
  }
  return await response.text();
};

// Fetch GET as JSON
export const fetchGet = <TResponse>(
  url: RequestInfo | URL
): Promise<TResponse> => {
  return fetch(url, {
    method: "get",
    headers: {
      Accept: "application/json",
    },
  })
    .then((response) => getResponseJson<TResponse>(response))
    .catch((err) => {
      console.log(`Could not Get from ${url}`);
      console.log(err);
      return Promise.reject(err);
    });
};

export const fetchGetKeepAlive = <TResponse>(
  url: RequestInfo | URL
): Promise<TResponse> => {
  return fetch(url, {
    method: "get",
    headers: {
      Accept: "application/json",
      Connection: "Keep-Alive",
      "Content-Type": "application/json",
      "Keep-Alive": "timeout=7200",
    },
  })
    .then((response) => getResponseJson<TResponse>(response))
    .catch((err) => {
      console.log(`Could not Get from ${url}`);
      console.log(err);
      return Promise.reject(err);
    });
};
// Fetch GET as Text
export const fetchGetText = (url: RequestInfo | URL): Promise<string> => {
  return fetch(url, {
    method: "get",
  })
    .then((response) => getResponseText(response))
    .catch((err) => {
      console.log(`Could not Get from ${url}`);
      console.log(err);
      return Promise.reject(err);
    });
};

// Fetch POST as JSON and return the response as JSON
export const fetchPost = <TBody, TResponse>(
  url: RequestInfo | URL,
  b: TBody
) => {
  const body = typeof b === "string" ? b : JSON.stringify(b);
  return fetch(url, {
    method: "post",
    body,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then((response) => <TResponse>getResponseJson(response))
    .catch((err) => {
      console.log(`Could not Post the following payload to ${url}:`, b);
      console.log(err);
      return Promise.reject(err);
    });
};

// Fetch Delete
export const fetchDelete = (url: RequestInfo | URL): Promise<string> => {
  return fetch(url, {
    method: "delete",
  })
    .then((response) => getResponseText(response))
    .catch((err) => {
      console.log(`Could not Delete from ${url}`);
      console.log(err);
      return Promise.reject(err);
    });
};

class ApiClass {
  applicationName = "Online Redirection Application";  
}

const Api = new ApiClass();

export default Api;