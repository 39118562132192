import {
  action,
  computed,
  makeObservable,
  observable,
  runInAction,
} from "mobx";


export const Pages = {
  Blank: "",
};

export default class RootStore {
  constructor() {
    makeObservable(this, { 
      
      electionId: observable,
      electionName: observable,
      activeBusyFuncs: observable,     
      isBusy: computed,    
      showWarning: observable,
      modified: observable,
      setShowWarning: action,
      setModified: action,
      setBusy: action,
      resetBusy: action
    });
  }
  
  currentPageName = ""; 
  electionId = "";
  electionName = "";
  activeBusyFuncs = 0;  

  get isBusy() {
    return this.activeBusyFuncs != 0;
  } 

  showWarning = false;
  modified = false;

  setShowWarning(warning) {
    this.showWarning = warning;
  }

  setModified(modified) {
    this.modified = modified;
  }
   
  setBusy() {
    this.activeBusyFuncs++;
  }

  resetBusy() {
    this.activeBusyFuncs--;
  }
}
