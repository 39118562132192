import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Box,Typography,Grid, FormControlLabel, TextField,Link,styled} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { StoreContext } from "../../StoreContext";
import dayjs from "dayjs";


type HeaderProps = {
    title: string;
  };

const ApplicantDetails = observer(({ title }: HeaderProps) => {
    const { onlineRedirectionStore } = useContext(StoreContext);    
    const { 
      familyName, 
      givenNames, 
      dateOfBirth, 
      phone, 
      isFamilyNameValid, 
      isPhoneNumberValid,
      isDOBValid,
      dobHelperText,
      showErrors
      } = onlineRedirectionStore; 
      
    const handleFamilyNameChange = (e) => {onlineRedirectionStore.setFamilyName(e.target.value);}
    const handleGivenNamesChange = (e) => {onlineRedirectionStore.setGivenNames(e.target.value);}
    const handleDateOfBirthChange = (value) => {onlineRedirectionStore.setDateOfBirth(value)}; 
    const handlePhoneChange = (e) => {onlineRedirectionStore.setPhone(e.target.value);}
    const DateOfBirthHelperText = showErrors && !isDOBValid ? dobHelperText: "";
    
    return (
      <Box component="section">
        {/* <StyleRoot> */}
        <Typography variant='h4' sx={{ fontWeight: 'bold'}}>If you're a silent elector, completing this form does not change your status as a silent elector.</Typography>  
        <Typography variant='h4' sx={{ fontWeight: 'bold'}}><span>If you need assistance completing this form, Please call our enrolment hotline on</span><span>&nbsp;<Link href="tel:1300 805 478" underline="always">{'1300 805 478'}</Link></span>.</Typography>                         
       
        <Typography variant="h2">{title}</Typography>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <Box sx={{  }}>
              <FormControlLabel control = {
                                        <TextField
                                            id="txtFamilyName"
                                            fullWidth={true} 
                                            required                                     
                                            variant="outlined"
                                            placeholder="e.g. Smith"
                                            inputProps={{
                                              maxLength: 45
                                            }}                                            
                                            value={familyName}
                                            onChange={handleFamilyNameChange}
                                            helperText={showErrors && !isFamilyNameValid ? "Please provide your family name" : ""}
                                        />
                                    } label="Family name" labelPlacement='top' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{  }}>
            <FormControlLabel control = {
                                        <TextField
                                            id="txtGivenName"
                                            fullWidth={true}                                            
                                            variant="outlined"
                                            placeholder="e.g. John"
                                            inputProps={{
                                                maxLength: 45
                                              }}
                                            value={givenNames}
                                            onChange={handleGivenNamesChange}
                                        />
                                    } label={"Given name(s)"} labelPlacement='top' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ }}>
            <FormControlLabel control = {
                                    <DatePicker 
                                      disableFuture 
                                      format="DD/MM/YYYY"                                                                                                                                   
                                      slotProps={{ textField: { required: true, fullWidth: true,variant: "outlined", helperText: DateOfBirthHelperText},                                                   
                                                   //calendarHeader:{ sx: { color:'#ffffff', backgroundColor:'#a30134',fontFamily: 'Poppins,Roboto,Arial,sans-serif',margin:'none', width:'100%' }}, 
                                                   toolbar:{ sx: { color:'#ffffff',backgroundColor:'#a30134',fontFamily: 'Poppins,Roboto,Arial,sans-serif',}},
                                                   layout:{ sx: {display:"unset"}}
                                                }} 
                                      onChange={handleDateOfBirthChange}                                    
                                      />
                                } label={<span>Date of birth<span style={{ color: '#e00',fontSize :'16px' }}>&nbsp;*</span></span>} labelPlacement='top'></FormControlLabel>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{  }}>
            <FormControlLabel control = {
                                        <TextField
                                            id="txtPhoneNumber"
                                            fullWidth={true} 
                                            required
                                            type="tel"                                           
                                            variant="outlined"
                                            placeholder="e.g. 0400111222"
                                            inputProps={{
                                                maxLength: 15
                                            }}
                                            value={phone}
                                            onChange={handlePhoneChange}
                                            helperText={showErrors && !isPhoneNumberValid ? "Please provide your phone number" : ""}
                                        />
                                    } label="Phone number" labelPlacement='top' />
            </Box>
          </Grid>
        </Grid>
        {/* </StyleRoot> */}
      </Box>
    );
  });
  
  export default ApplicantDetails;
