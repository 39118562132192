import React, { useContext,MouseEventHandler } from "react";
import { StoreContext } from "../../StoreContext";
import { observer } from "mobx-react";
import { Grid, Typography, Hidden, Box,Button, styled} from '@mui/material';
import ApplicantDetails from './applicant-details';
import EnrolledAddress from './enrolled-address';
import PostalAddress from './postal-address';
import DeclarationSection from './declaration-section';
import { Margin, Padding } from "@mui/icons-material";

const StyleRoot = styled("div")(({ theme }) => ({
    "& .root": {
        width: '100%',
        height: '100%',
        marginBottom: '-64px',
        left: '0px',
        right: '0px',
        backgroundColor: '#FFFFFF'        
    },
    "& .container": {
        PaddingBottom: '5px'
    },   
    "& .oraform": {
        padding:'10px',
        [theme.breakpoints.down('xs')]: {
            marginTop: '50px'
        }      
    }, 
    "& .pageTitle": {
        marginBottom: '30px'
    }, 
    "& .submitBtn": {
        padding: '12px 12px',
        minWidth: '160px',
        fontSize: '18px',
        cursor: 'pointer',
        color: theme.palette.primary.main,
        borderColor: theme.palette.primary.main
    },  
    "& .submitBtnDisabled": {
        padding: '12px 12px',
        minWidth: '160px',
        fontSize: '18px',
        cursor: 'pointer',
        color: theme.palette.secondary.dark,
        borderColor: '#39444F !important',
        Border:'100px !important'
    },  
    "& .formContainer": {
        display: 'flex', 
        justifyContent: 'center',  
        alignItems:'center',
        marginTop: '0px',
        marginBottom: '0px'
    }, 
    "& .btnContainer": {
        justifyContent: 'center',  
        alignItems:'center',
        textAlign: 'center',
        marginBottom: '20px',
        [theme.breakpoints.down('sm')]: {
            textAlign:'center'
        }
    },  
    "& .errorMsg": {
        color: theme.palette.primary.main,
        fontSize: '18px',
        ['& a']: {
            color: '#a30134',
            textDecoration: 'underline'
        }
    },  
    "& .readspeaker": {
        marginTop: '100px',
        marginBottom: '35px'
    },
    "& .footer": {
        width: '100%',
        height: '100%',
        marginBottom: '-64px',
        left: '0px',
        right: '0px',
        backgroundColor: '#FFFFFF'
    },    
      "& .MuiErrorCustom": {
        color: '#f44336'
      },     
    "& .inputFieldCell": {
        paddingRight: '25px'
      },
      "& .labelText": {
        alignItems: 'left',
        marginRight: '20px',
        display: 'flex',
        cursor: 'normal',
        textTransform: 'none',
        paddingTop: '10px',
        paddingBottom: '10px'
      }, 
      "& .helperTextError": {
        color: '#A30134'
      },    
    "& .radioText": {
        fontWeight: 700,
          fontStretch: 'normal',
          color: '#0E3A6C',
          lineHeight: '1.2'
      } ,
      "& .LabelWithPointer": {
        fontSize: '18px',
        ['& span']: {
            cursor: 'pointer !important'
        },
      },  
  }));


const OraContents = observer(() => { 

    const { onlineRedirectionStore } = useContext(StoreContext);
    const { isEntitledDeclareChecked, 
        isTrueCorrectDeclareChecked
      } = onlineRedirectionStore
    
    //const stretchReadspeakerRow = () => {return 'stretchReadspeakerRow()';}
    const stretchReadspeakerRow: MouseEventHandler<HTMLAnchorElement> = () => {
        return "stretchReadspeakerRow()";
      };
   
    return (
        <StyleRoot>
            <div className="root">                                                          
                <div className="oraform">
                    <form id="SubmitORAForm">
                        <div id="oraForm" className="readthis">                               
                            <div>
                            <Grid container id="gdOraFormHeader">
                                <Grid item lg={2} md={2} sm={1}> &nbsp;</Grid>                    
                                <Grid item lg={8} md={8} sm={10} xs={12} className="container">
                                    <Hidden only={['sm', 'xs']}>
                                        <div id="readspeakerPlayer" className="readspeaker">
                                            <div id="readspeaker_button1" className="rs_skip rsbtn rs_preserve">
                                                { /* eslint-disable-next-line */ }
                                                <a  className="rsbtn_play" accessKey="L" title="Listen to this page using ReadSpeaker" onClick={stretchReadspeakerRow}
                                                href={onlineRedirectionStore.rsUrl} aria-expanded="false">
                                                <span className="rsbtn_left rsimg rspart"><span className="rsbtn_text"><span>Listen</span></span></span>
                                                <span className="rsbtn_right rsimg rsplay rspart"></span>
                                                </a>
                                            </div>
                                        </div>
                                    </Hidden>
                                    <Typography variant="h1" sx={{ marginBottom:{ xs: '10px',sm:'20px',md:'30px' } ,marginTop:{ xs: '30px',sm:'60px',md:'80px' } }}>Online redirection of ballot pack application</Typography>
                                </Grid>                   
                                <Grid item lg={2} md={2} sm={1}>&nbsp;</Grid>
                            </Grid>                 
                            </div>                                          
                            <div>                                    
                                <Grid container>
                                    <Grid item lg={2} md={2} sm={1}>&nbsp;</Grid>
                                    <Grid item lg={8} md={8} sm={10} xs={12} className="container">                                            
                                        <ApplicantDetails title={'Applicant details'} />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={1}>&nbsp;</Grid>
                                </Grid>            
                            </div>  
                            <div>                                    
                                <Grid container>
                                    <Grid item lg={2} md={2} sm={1}>&nbsp;</Grid>
                                    <Grid item lg={8} md={8} sm={10} xs={12} className="container">                                            
                                        <EnrolledAddress title={'Your enrolled address'} />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={1}>&nbsp;</Grid>
                                </Grid>            
                            </div> 
                            <div>                                    
                                <Grid container>
                                    <Grid item lg={2} md={2} sm={1}>&nbsp;</Grid>
                                    <Grid item lg={8} md={8} sm={10} xs={12} className="container">                                            
                                        <PostalAddress title={'Your redirection postal address'} />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={1}>&nbsp;</Grid>
                                </Grid>            
                            </div> 
                            <div>                                    
                                <Grid container>
                                    <Grid item lg={2} md={2} sm={1}>&nbsp;</Grid>
                                    <Grid item lg={8} md={8} sm={10} xs={12} className="container">                                            
                                        <DeclarationSection title={'Declaration'} />
                                    </Grid>
                                    <Grid item lg={2} md={2} sm={1}>&nbsp;</Grid>
                                </Grid>   
                            </div> 
                        </div>   
                    </form>             
                </div>               
            </div>
        </StyleRoot>
    )});

export default OraContents;
