import { observable, action, runInAction, makeObservable, computed } from "mobx";
import onlineRedirectionService from "../../services/ora/online.redirection.service";
import RootStore from "../root.store";
import dayjs from "dayjs";
import { Tools } from '../../utils/tools';
import {Council} from '../../types/common';
import { ElectionCategory,ElectionMethodType,ElectionType } from "../../enums";
import {OnlineRedirectionApplicationRequest} from '../../types/online.redirection.type';

export default class OnlineRedirectionStore {
  constructor(public readonly rootStore: RootStore) {
    makeObservable(this, { 
      initOnlineRedirectionStore : action,
      showErrors : observable,  
      setWebsiteBackUrl : action,
      setGoogleMapApiKey : action,
      ReadSpeakerUrl : observable,
      isBusy : observable,
      rsUrl : observable,
      setReadSpeakerUrl : action,
      electionId : observable,
      electorateId : observable,
      electionCategoryId : observable,
      electionMethodId : observable,
      electionTypeId : observable,
      redirectToPage : observable,
      familyName : observable,
      givenNames : observable,
      dateOfBirth : observable,
      phone: observable,
      enrolledAddress : observable,
      latitude  : observable,
      longitude  : observable,
      isOutOfVIC: observable,
      isGoogleAddressSelected: observable,    
      council  : observable,
      councilList : observable,
      ward  : observable,
      postalAddressTypeId: observable,
      postalAddress  : observable,
      postalPropertyName  : observable,
      postalFlatUnit  : observable,
      postalStreetNumber  : observable,
      postalStreetName  : observable,
      postalSuburb  : observable,
      postalState  : observable,
      postalPostCode  : observable,
      overseasAddressLine1 : observable,
      overseasAddressLine2 : observable,
      overseasAddressLine3 : observable,
      overseasLocalitySuburb  : observable,
      overseasPostZipCode  : observable,
      overseasState  : observable,
      country  : observable,      
      councilHelperText : computed,
      postalAddressHelperText : computed,
      dobHelperText : computed,
      enrolledAddressHelperText : computed,
      dontNeedToVoteHelperText : computed,          
      isSameAsEnrolledAddress : computed,
      isOverseasAddress : computed,
      isAUAddress : computed,
      dateOfBirthFormatted : computed,      
      isFamilyNameValid : computed,
      isDOBValid : computed,
      isPhoneNumberValid : computed,
      isEnrolledAddressValid : computed,
      isCouncilValid : computed,
      isInputCouncilValid : computed,      
      isPostalAddressValid : computed,
      isSelectedPostalAddressValid : computed,   
      isPostalStreetNameValid : computed,
      isPostalSuburbValid : computed,
      isPostalStateValid : computed,
      isPostalPostCodeValid : computed,
      isOverseasAddressLine1Valid : computed,
      isCountryValid : computed,
      isDeclarationChecked : computed,
      isFormValid : computed, 
      setFamilyName : action,
      setGivenNames : action,
      setPhone : action,
      setEnrolledAddress : action,
      setPostalAddressType : action,
      setPostalAddress : action,
      setPostalPropertyName : action,
      setPostalFlatUnit : action,
      setPostalStreetNumber : action,
      setPostalStreetName : action,
      setPostalSuburb : action,
      setPostalState : action,
      setPostalPostCode : action,
      setOverseasAddressLine1 : action,
      setOverseasAddressLine2 : action,
      setOverseasAddressLine3 : action,
      setOverseasLocalitySuburb : action,
      setOverseasPostZipCode : action,
      setOverseasState : action,
      setCountry : action,
      setElectionId : action,
      unableToFindMyAddress : observable,
      tempPostalPropertyName  : observable,
      tempPostalFlatUnit  : observable,
      tempPostalStreetNumber  : observable,
      tempPostalStreetName  : observable,
      tempPostalSuburb  : observable,
      tempPostalState  : observable,
      tempPostalPostCode  : observable,
      setUnableToFindMyAddress : action,  
      setTempPostalPropertyName : action,
      setTempPostalFlatUnit : action,
      setTempPostalStreetNumber : action,
      setTempPostalStreetName : action,
      setTempPostalSuburb : action,
      setTempPostalState : action,
      setTempPostalPostCode : action,
      isEntitledDeclareChecked: observable,
      isTrueCorrectDeclareChecked: observable,
      setEntitledDeclarationCheck : action,
      setTrueCorrectDeclarationCheck : action,
      submitOnlineRedirectionApplication : action,
      setCouncilWard : action,
      setCouncil : action,
      setWard : action,
      setAustralianPostalAddressFields : action,
      setOutOfVIC : action,
      setElectorateId : action,
      setShowErrors : action,
      setIsGoogleAddressSelected : action,
      setSelectedCouncilValid : action,
      isCouncilInApprovedList : action,
      selectedCouncilValid : observable,
      clearOverseasAddressFields: action,
      clearPostalAddressFields: action,
      openSubmitButtonDialogBox: observable,
      setOpenSubmitButtonDialogBox: action,
      trackingNumber: observable,
      submitSuccess: observable,
      setTrackingNumber: action,
      setSubmitSuccess : action,
      setDateOfBirth : action,
      submitPostalAddressTypeId : observable,
      setSubmitPostalAddressTypeId : action,
      disablePostalPropertyName : observable,
      disablePostalFlatUnit : observable,
      disablePostalStreetNumber : observable,
      disablePostalStreetName : observable,
      disablePostalSuburb : observable,
      disablePostalState : observable,
      disablePostalPostCode : observable,
      setDisablePostalPropertyName : action,
      setDisablePostalFlatUnit : action,
      setDisablePostalStreetNumber : action,
      setDisablePostalStreetName : action,
      setDisablePostalSuburb : action,
      setDisablePostalState : action,
      setDisablePostalPostCode : action,
      disablePostalAddressFields : action,
      setCloudFlareSiteKey : action
    });
    this.initOnlineRedirectionStore();
    
  }     
  showErrors = false; // set validation off when initial page loading
  GOOGLE_API_KEY = "AIzaSyArWTQOaGGO4zYzsGZzaNJjkNigbFMF7dk";
  CLOUD_FLARE_SITE_KEY =  "0x4AAAAAAAahF1smN_ZdMQc4";
  websiteBackUrl = "https://www.vec.vic.gov.au";
  ReadSpeakerUrl = "//app-oc.readspeaker.com/cgi-bin/rsent?customerid=6976&lang=en_au&readclass=readthis&url=[currentUrl]";
  isBusy = false;
  rsUrl = "";  
  electionId = "";
  electionName = "";
  electorateId = ""; 
  electionCategoryId : ElectionCategory = ElectionCategory.Unset;
  electionMethodId : ElectionMethodType = ElectionMethodType.Unset;
  electionTypeId : ElectionType = ElectionType.Unset;
  redirectToPage = ""; 
  
  isEntitledDeclareChecked = false;
  isTrueCorrectDeclareChecked = false;
  familyName = "";
  givenNames = "";
  dateOfBirth = "";  
  phone = "";

  enrolledAddress= "";
  latitude = "";
  longitude = "";
  elecCouncil= [];
  isOutOfVIC = false;
  isGoogleAddressSelected = false;
   
  councilList: Council[] = [];
  council = "";
  ward = "";
  postalAddressTypeId = "";
  postalAddress = "";
  postalPropertyName = "";
  postalFlatUnit = "";
  postalStreetNumber = "";
  postalStreetName = "";
  postalSuburb = "";
  postalState = "";
  postalPostCode = "";
  overseasAddressLine1 = "";
  overseasAddressLine2= "";
  overseasAddressLine3 = "";
  overseasLocalitySuburb = "";
  overseasPostZipCode = "";
  overseasState = "";
  country = "";
  submittedResult = null;
  selectedCouncilValid = true;
  unableToFindMyAddress = false;

  tempPostalPropertyName = "";
  tempPostalFlatUnit = "";
  tempPostalStreetNumber = "";
  tempPostalStreetName = "";
  tempPostalSuburb = "";
  tempPostalState = "";
  tempPostalPostCode = "";
  selectedEnrolledAddressComponent = null;  
  openSubmitButtonDialogBox = false;
  trackingNumber = "";
  submitSuccess = false;
  submitPostalAddressTypeId = "";

  disablePostalPropertyName = true;
  disablePostalFlatUnit = true;
  disablePostalStreetNumber = true;
  disablePostalStreetName = true;
  disablePostalSuburb = true;
  disablePostalState = true;
  disablePostalPostCode = true;


   // Init ora store 
  initOnlineRedirectionStore() {
    // set app configuration values
    this.setWebsiteBackUrl();
    this.setGoogleMapApiKey();  
    this.setCloudFlareSiteKey();     
    
    // loading the current valid election
    onlineRedirectionService
      .getClosestElection()
      .then((response) => {
        runInAction(() => {
          if (response) {
            // current VEC date between entitlement and opening nominations 
            if (response.electionId && response.currentDateTimeWithinRange) 
            {             
              this.electionId = response.electionId;                                    
              this.electionName = response.electionName;               
              this.setCouncilList();
              this.setReadSpeakerUrl();
              this.setShowErrors(false);
              console.log('The online redirection ballot pack application is loading for an active election '+ response.electionName + ' ('+ response.electionId+')');
            }
            else {
              console.log('The online redirection ballot pack application has expired.');
              window.location.href = this.websiteBackUrl; 
            }
          } else {
            console.log('No active election found.');
            window.location.href = this.websiteBackUrl; 
          }
        });
      })
      .catch((error) => {
        console.log('Application failed. '+ error);
        window.location.href = this.websiteBackUrl; 
      });
  }

   // Set screen reader
  setReadSpeakerUrl() {
    this.rsUrl = this.ReadSpeakerUrl;
    let currentUrl = "https://" + window.location.hostname;
    this.rsUrl = this.rsUrl.replace('[currentUrl]',currentUrl);
    console.log('set the reader '+this.rsUrl);
  }

  // set website back url
  setWebsiteBackUrl() {
    onlineRedirectionService
      .getWebSiteBackUrl()
      .then((response) => {
        if(response)
          this.websiteBackUrl = response;         
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // set google map api key
  setGoogleMapApiKey() {
    onlineRedirectionService
      .getGoogleMapApiKey()
      .then((response) => {
        if(response)
          this.GOOGLE_API_KEY = response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  setCloudFlareSiteKey() {
    onlineRedirectionService
      .getCloudFlareSiteKey()
      .then((response) => {
        if(response)
          this.CLOUD_FLARE_SITE_KEY = response;
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // Application details
  setFamilyName = (val: string) => { this.familyName = val; }
  setGivenNames = (val: string) => { this.givenNames = val; } 
  setPhone = (val: string) => { this.phone = val; }
  setDateOfBirth = (val: string) => { this.dateOfBirth = val }
  // Your enrolled address
  setEnrolledAddress = (val: string) => { this.enrolledAddress = val;}
  setGoogleSelectedEnrolledAddress = () => { 
    const addressTextBox = document.getElementById(`txtAddressLookup-enrolledAddress`) as HTMLInputElement;
    if (addressTextBox) {          
      this.setEnrolledAddress(addressTextBox.value);  
      console.log(addressTextBox.value);        
    }       
  }
  setCouncil = (val: string) => { this.council = val; }
  setElectorateId = (val: string) => { this.electorateId = val; }
  setWard = (val: string) => { this.ward = val.toLowerCase() === "Unsubdivided".toLowerCase() ? "": val;}
  setOutOfVIC = (val: boolean) => { this.isOutOfVIC = val; }
  setIsGoogleAddressSelected = (val: boolean) => { this.isGoogleAddressSelected = val; }
  setSelectedCouncilValid = (val: boolean) => { this.selectedCouncilValid = val; }
  setSubmitPostalAddressTypeId = (val: string) => { this.submitPostalAddressTypeId = val; }

  // Set council list
  setCouncilList() {
    onlineRedirectionService
      .getCouncils(this.electionId)
      .then((response) => {
        runInAction(() => {
          if (response) {
            this.councilList = response;            
          } else {
            console.log('No councils found')
          }
        });
      })
      .catch((error) => {
        console.log(error);
        window.location.href = this.websiteBackUrl; 
      });
  }

  isCouncilInApprovedList(inputCouncilName: string){
    return this.councilList.some(item => item.electorateName.toLowerCase() === inputCouncilName.toLowerCase());
  }

  setCouncilWard = (place :  google.maps.places.PlaceResult) => { 
    const latitude = place.geometry?.location?.lat();
    const longitude = place.geometry?.location?.lng();  
    this.setOutOfVIC(false);
    this.setCouncil("");
    this.setWard("");   
    this.electorateId = "";
    this.selectedCouncilValid = true;  

    onlineRedirectionService.getElectoratesSummary(latitude,longitude).then((result) => { 
        runInAction(() => {             
            if(result && result.district != null) {               
               this.setGoogleSelectedEnrolledAddress();
               this.setOutOfVIC(false);
               if(result.council && result.council.electorateType === "Council")
               {   
                    this.setCouncil(result.council.electorateName);
                    this.electorateId = result.council.electorateId;
                    if(!this.isCouncilInApprovedList(result.council.electorateName))                   
                    { 
                        this.selectedCouncilValid = false; 
                    } 
               }  
               
               if(result.ward && result.ward.electorateType === "Ward")
               { 
                  this.setWard(result.ward.electorateName);
               }  
            }
            else {             
                this.setOutOfVIC(true);
            }                
        });
    });      
  }

  setAustralianPostalAddressFields = (place: google.maps.places.PlaceResult) => {
    const addressComponents = place.address_components;     
    if(addressComponents) { 
      this.clearPostalAddressFields();     
      addressComponents.forEach((component) => {
        switch (component.types[0]) {
          case 'postal_town':
            this.postalPropertyName = component.long_name;           
            break;
          case 'subpremise':
            this.postalFlatUnit = component.long_name;            
            break;
          case 'street_number':
            this.postalStreetNumber = component.long_name;           
              
            break;
          case 'route':
            this.postalStreetName = component.long_name;           
            break;
          case 'locality':
            this.postalSuburb = component.long_name;           
            break;
          case 'administrative_area_level_1':
            this.postalState = component.short_name;           
            break;              
          case 'postal_code':
            this.postalPostCode = component.long_name;
            break;
          default:
            break;
        }
      });

      //check valuse found, if not enable for edit
      this.setDisablePostalPropertyName(this.postalPropertyName.length > 0);
      this.setDisablePostalFlatUnit(this.postalFlatUnit.length > 0);
      this.setDisablePostalStreetNumber(this.postalStreetNumber.length > 0);
      this.setDisablePostalStreetName(this.postalStreetName.length > 0);
      this.setDisablePostalSuburb(this.postalSuburb.length > 0);
      this.setDisablePostalState(this.postalState.length > 0);
      this.setDisablePostalPostCode(this.postalPostCode.length > 0);
    }
  }

  setPostalAddressType = (value: string) => { 
    this.postalAddressTypeId = value;

    if(!this.showErrors)
      this.setSubmitPostalAddressTypeId(value);

    switch (this.postalAddressTypeId) {
      case "0":  // same as enrolled          
          this.setPostalAddress(this.enrolledAddress);
          this.clearOverseasAddressFields();
          this.clearPostalAddressFields();
      case "1": // AU
          this.setPostalAddress("");
          this.clearOverseasAddressFields();
      case "2": // overseas
          this.setPostalAddress("");
          this.clearPostalAddressFields();
      default:
          this.setPostalAddress(this.enrolledAddress);
          this.clearOverseasAddressFields();
          this.clearPostalAddressFields();
    }
  }

  getPostalAddressTextBoxValue = () => {   
    const addressTextBox = document.getElementById(`txtAddressLookup-auPostalAddress`) as HTMLInputElement;

    if (addressTextBox && !this.unableToFindMyAddress) {   
      return addressTextBox.value;         
    } 
    else
    {
      return this.postalAddress;
    }     
  }
   
  // Your postal address
   setPostalAddress = (val: string) => { this.postalAddress = val; }
   setPostalPropertyName = (val: string) => { this.postalPropertyName = val; }
   setPostalFlatUnit = (val: string) => { this.postalFlatUnit = val; }
   setPostalStreetNumber = (val: string) => { this.postalStreetNumber = val; }
   setPostalStreetName = (val: string) => { this.postalStreetName = val; }
   setPostalSuburb = (val: string) => { this.postalSuburb = val; }
   setPostalState = (val: string) => { this.postalState = val; }
   setPostalPostCode = (val: string) => { this.postalPostCode = val; }
   // Overseas postal address
   setOverseasAddressLine1 = (val: string) => { this.overseasAddressLine1 = val; }
   setOverseasAddressLine2 = (val: string) => { this.overseasAddressLine2 = val; }
   setOverseasAddressLine3 = val => { this.overseasAddressLine3 = val; }
   setOverseasLocalitySuburb = (val: string) => { this.overseasLocalitySuburb = val; }
   setOverseasPostZipCode = (val: string) => { this.overseasPostZipCode = val; }
   setOverseasState = (val: string) => { this.overseasState = val; }
   setCountry = (val: string) => { this.country = val; }
   setElectionId = (val: string) => { this.electionId = val; }
   setUnableToFindMyAddress = val => { this.unableToFindMyAddress = val; }
   // Your postal address   
   setTempPostalPropertyName = (val: string) => { this.tempPostalPropertyName = val; }
   setTempPostalFlatUnit = (val: string) => { this.tempPostalFlatUnit = val; }
   setTempPostalStreetNumber = (val: string) => { this.tempPostalStreetNumber = val; }
   setTempPostalStreetName = (val: string) => { this.tempPostalStreetName = val; }
   setTempPostalSuburb = (val: string) => { this.tempPostalSuburb = val; }
   setTempPostalState = (val: string) => { this.tempPostalState = val; }
   setTempPostalPostCode = (val: string) => { this.tempPostalPostCode = val; }
    // Declaration checkboxes
   setEntitledDeclarationCheck = (val: boolean) => {this.isEntitledDeclareChecked = val;}
   setTrueCorrectDeclarationCheck = (val: boolean) => {this.isTrueCorrectDeclareChecked = val;}
   setShowErrors = (val: boolean) => {this.showErrors = val;}


   disablePostalAddressFields(disable: boolean) {
    this.setDisablePostalPropertyName(disable);
    this.setDisablePostalFlatUnit(disable);
    this.setDisablePostalStreetNumber(disable);
    this.setDisablePostalStreetName(disable);
    this.setDisablePostalSuburb(disable);
    this.setDisablePostalState(disable);
    this.setDisablePostalPostCode(disable);
   }  

   // Clear text fields
   clearPostalAddressFields() {
    this.setPostalAddress("");
    this.setPostalPropertyName("");
    this.setPostalFlatUnit("");
    this.setPostalStreetNumber("");
    this.setPostalStreetName("");
    this.setPostalSuburb("");
    this.setPostalState("");
    this.setPostalPostCode("");
    this.setUnableToFindMyAddress(false);
  }  

  clearTempPostalAddressFields() {    
    this.setTempPostalPropertyName("");
    this.setTempPostalFlatUnit("");
    this.setTempPostalStreetNumber("");
    this.setTempPostalStreetName("");
    this.setTempPostalSuburb("");
    this.setTempPostalState("");
    this.setTempPostalPostCode("");
  }  

  // Clear overseas address fields
  clearOverseasAddressFields() {
    this.setOverseasAddressLine1("");
    this.setOverseasAddressLine2("");
    this.setOverseasAddressLine3("");
    this.setOverseasLocalitySuburb("");
    this.setOverseasPostZipCode("");
    this.setOverseasState("");
    this.setCountry("");
  }  

  // Clear the form
  clearSubmittedData = () => {
    this.familyName = "",
    this.givenNames = "",
    this.phone = "",
    this.enrolledAddress = "",
    this.council = "",
    this.ward = "",
    this.postalAddressTypeId = "",
    this.postalAddress = "",
    this.postalPropertyName = "",
    this.postalFlatUnit = "",
    this.postalStreetNumber = "",
    this.postalStreetName = "",
    this.postalSuburb = "",
    this.postalState = "",
    this.postalPostCode = "",
    this.overseasAddressLine1 = "",
    this.overseasAddressLine2 = "",
    this.overseasAddressLine3 = "",
    this.overseasLocalitySuburb = "",
    this.overseasPostZipCode = "",
    this.overseasState = "",
    this.country = "",
    this.electionId = "",
    this.electorateId = "",
    this.isTrueCorrectDeclareChecked = false;
    this.isEntitledDeclareChecked = false;  
    this.showErrors = false;  
    this.selectedEnrolledAddressComponent = null;  
    this.openSubmitButtonDialogBox = false;
    this.trackingNumber = "";
    this.submitSuccess = false;   
    this.submitPostalAddressTypeId = "";    
    this.disablePostalPropertyName = true;
    this.disablePostalFlatUnit = true;
    this.disablePostalStreetNumber = true;
    this.disablePostalStreetName = true;
    this.disablePostalSuburb = true;
    this.disablePostalState = true;
    this.disablePostalPostCode = true;
  }


  openResultDialogBox = (isSuccess: boolean) => {
    this.setSubmitSuccess(isSuccess);   
    this.setOpenSubmitButtonDialogBox(true);             
  } 
  
  // form submission
  submitOnlineRedirectionApplication = () => {  
    console.log('Device type -> '+Tools.getDeviceType());
    try {
      this.setSubmitPostalAddressTypeId(this.postalAddressTypeId);
      if(!this.isFormValid) {
        this.setShowErrors(true);
        window.scrollTo(0, 0);
      }  
      else { 
        const validatedApplication = this.validateApplication();
        
        if (validatedApplication) {
          
            // Call service to save the validated application         
            onlineRedirectionService
            .postOnlineRedirectionApplication(validatedApplication)
            .then((response) => {
              runInAction(() => {
                if (response && response.status == 1 && response.onlineRedirectionApplicationId.toString().length > 0) {                
                  console.log('success '+ JSON.stringify(response,null,2)); 
                  this.clearSubmittedData();                
                  this.setTrackingNumber(response.trackingNumber);
                  this.openResultDialogBox(true);              
                } else {
                  console.log('Application submission failed.');                
                  this.openResultDialogBox(false); 
                }
              });
            })
            .catch((error) => {
              console.log(error);
              this.openResultDialogBox(false);
            });
        } else {
            this.setShowErrors(true);
            console.error("Application validation failed.");
            this.openResultDialogBox(false) ;
        }      
      }    
    }
    catch(error){
      console.log('submitting failed.'+ error);
      this.openResultDialogBox(false);
    }    
  }

  validateApplication(): OnlineRedirectionApplicationRequest | null {
    try 
    {  
        const application: Partial<OnlineRedirectionApplicationRequest> = {
            electionId: this.electionId,
            electorateName: this.council,
            electorateId: this.electorateId,
            familyName: this.familyName,
            givenNames: this.givenNames,
            dateOfBirth: this.dateOfBirthFormatted,
            phone: this.phone,
            enrolledAddress: this.enrolledAddress,
            ward: this.ward,
            postalAddressTypeId: Tools.parseInteger(this.postalAddressTypeId),
            postalAddress: this.getPostalAddressTextBoxValue(),
            postalPropertyName: this.postalPropertyName,
            postalFlatUnit: this.postalFlatUnit,
            postalStreetNumber: this.postalStreetNumber,
            postalStreetName: this.postalStreetName,
            postalSuburb: this.postalSuburb,
            postalState: this.postalState,
            postalPostCode: this.postalPostCode,
            overseasAddressLine1: this.overseasAddressLine1,
            overseasAddressLine2: this.overseasAddressLine2,
            overseasAddressLine3: this.overseasAddressLine3,
            overseasLocalitySuburb: this.overseasLocalitySuburb,
            overseasPostZipCode: this.overseasPostZipCode,
            overseasState: this.overseasState,
            country: this.country,
            clientApplicationDateTime: Tools.getCurrentUTCDateTime(),
            captchaToken: sessionStorage.getItem('cfORAToken'),
            clientDeviceType : Tools.getDeviceType()
        };       
        
        return application as OnlineRedirectionApplicationRequest;

    } catch (error) {
        console.error("An error occurred during application validation:", error);
        return null;
    }
  }

  setOpenSubmitButtonDialogBox = (val: boolean) => { this.openSubmitButtonDialogBox = val; }
  setTrackingNumber = (val: string) => { this.trackingNumber = val; }
  setSubmitSuccess = (val: boolean) => { this.submitSuccess = val; }

  setDOBHelperText(dateOfBirth: string | null): string {
    if (!dateOfBirth) {
        return "Please provide your date of birth";
    }

    const dob = dayjs(dateOfBirth, 'DD/MM/YYYY', true);

    if (!dob.isValid()) {
        return "Please enter a valid date of birth in the format DD/MM/YYYY";
    }

    if (dob.isAfter(dayjs())) {
        return "Your date of birth cannot be in the future. Please enter a valid date";
    }

    return "";
  }

  setDisablePostalPropertyName = (val: boolean) => { this.disablePostalPropertyName = val; }
  setDisablePostalFlatUnit = (val: boolean) => { this.disablePostalFlatUnit = val; }
  setDisablePostalStreetNumber = (val: boolean) => { this.disablePostalStreetNumber = val; }
  setDisablePostalStreetName = (val: boolean) => { this.disablePostalStreetName = val; }
  setDisablePostalSuburb = (val: boolean) => { this.disablePostalSuburb = val; }
  setDisablePostalState = (val: boolean) => { this.disablePostalState = val; }
  setDisablePostalPostCode = (val: boolean) => { this.disablePostalPostCode = val; }


  
  // Get computed internal values
  get isSameAsEnrolledAddress() { return this.isPostalAddressValid && this.postalAddressTypeId == "0"; }
  get isOverseasAddress() { return this.isPostalAddressValid && this.postalAddressTypeId == "2"; }
  get isAUAddress() { return  this.isPostalAddressValid && this.postalAddressTypeId == "1"; }
  get dateOfBirthFormatted() { return dayjs(this.dateOfBirth).format('DD/MM/YYYY') }
  get isFamilyNameValid() { return (this.familyName && this.familyName.trim() != ""); }
  get isDOBValid() { return Tools.isDateValidator(this.dateOfBirth) && !(dayjs(this.dateOfBirth).isAfter(dayjs()));}
  get isPhoneNumberValid() { return this.phone && this.phone.trim() != "" && Tools.isPhoneValidator(this.phone); }
  // Enrolled address validation
  get isEnrolledAddressValid() {
    const hasValidAddress = this.enrolledAddress && this.enrolledAddress.trim() !== '';
    const isAddressValidForVIC = hasValidAddress && !this.isOutOfVIC;
    return this.isGoogleAddressSelected ? isAddressValidForVIC : hasValidAddress;
  }
  get isCouncilValid() { return (this.council && this.council !== "Select Council"); }
  get isInputCouncilValid() { return (this.isEnrolledAddressValid && this.selectedCouncilValid); }
  get isPostalAddressValid() { return (this.postalAddressTypeId && this.postalAddressTypeId != ""); }

  get isSelectedPostalAddressValid(): boolean {
    if (!this.isPostalAddressValid) {
        return false;
    }

    switch (this.postalAddressTypeId) {
        case "0":            
            return true;
        case "1": // AU
            return (                
                this.isPostalStreetNameValid &&
                this.isPostalSuburbValid &&
                this.isPostalStateValid &&
                this.isPostalPostCodeValid
            );
        case "2": // overseas
            return this.isOverseasAddressLine1Valid && this.isCountryValid;
        default:
            return false;
    }
  }

  // Au postal address validation   
  get isPostalStreetNameValid() { return this.isAUAddress && (this.postalStreetName == null || this.postalStreetName.trim()) == "" ? false : true; }
  get isPostalSuburbValid() { return this.isAUAddress && (this.postalSuburb == null || this.postalSuburb.trim() == "") ? false : true; }
  get isPostalStateValid() { return this.isAUAddress && (this.postalState == null || this.postalState.trim() == "") ? false : true; }
  get isPostalPostCodeValid() { return this.isAUAddress && (this.postalPostCode == null || this.postalPostCode.trim() == "") ? false : true; }
  // Overseas address validation
  get isOverseasAddressLine1Valid() {return  this.isOverseasAddress && (this.overseasAddressLine1 == null || this.overseasAddressLine1.trim() == "") ? false : true;}
  get isCountryValid() { return (this.country == null || this.country.trim() == "") ? false : true;}
  // Declarartion section validation
  get isDeclarationChecked() { return (this.isEntitledDeclareChecked && this.isTrueCorrectDeclareChecked);}
  // Entire form Validation  
  get isFormValid() {
      return (
          this.isFamilyNameValid
          && this.isDOBValid
          && this.isPhoneNumberValid
          && this.isEnrolledAddressValid
          && this.isInputCouncilValid
          && this.isCouncilValid
          && this.isPostalAddressValid
          && this.isSelectedPostalAddressValid
          && this.isEntitledDeclareChecked  
          && this.isTrueCorrectDeclareChecked
          && this.isDeclarationChecked
      )
  }  

  // Helper texts
  get councilHelperText() { return this.isCouncilValid ? "" : "Please select council"; }   
  get postalAddressHelperText() { return this.isPostalAddressValid ? "" : "Please select your redirection postal address"; }
  get dobHelperText() { return this.setDOBHelperText(this.dateOfBirth) }
  get enrolledAddressHelperText () { return (this.isEnrolledAddressValid && !this.isOutOfVIC) ? ""
                  : (this.isOutOfVIC && this.isGoogleAddressSelected) 
                  ? "Your address did not match a Victorian electorate. It may be incorrect, or you are entering an address outside of Victoria. Please check and try again"
                  : "Please provide your full enrolled address"
  }

  get dontNeedToVoteHelperText() 
  { 
    return (!this.isInputCouncilValid) ? "This address is in a council that is not currently having an election. You don't need to vote for this council" : ""; 
  }  
}

