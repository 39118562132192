import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'; // Import UTC plugin
import MobileDetect from 'mobile-detect';

var Tools = {

    MAXINT: Math.pow(2,31) - 1,
   
    isDateValidator: function(value) {
        console.log('IsValidDOB -> '+dayjs(value, 'DD/MM/YYYY').isValid());
        return dayjs(value, 'DD/MM/YYYY').isValid();
    },

    isEmailValidator : function (value) {
        let reg = /^([a-zA-Z0-9'_\-\.!#$%&*+=?^`{}|\"~\\,:;<>()\[\]/]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-\[\]]+\.)+))([a-zA-Z\[\]]{2,18}|[0-9\[\]]{1,3})(\]?)$/;
        return reg.test(value);
    },
    
    isPhoneValidator : function(value) {
        let reg = /^[0-9\(\)\-\+\s]*[0-9]{1,50}[0-9\(\)\-\s]*$/
        return reg.test(value);
    },

    validateNumber : function (event) {
        const keyCode = event.keyCode;
        // Allow: backspace, delete, tab, escape, enter
        if (keyCode == 46 || keyCode == 8 || keyCode == 9 || keyCode == 27 || keyCode == 13 ||
            // Allow: Ctrl+A
            (keyCode == 65 && event.ctrlKey === true) ||
            // Allow: home, end, left, right
            (keyCode >= 35 && keyCode <= 39)) {
            // Let it happen, don't do anything
            return true;
        }
        // Ensure that it is a number and stop the keypress
        if ((keyCode < 48 || keyCode > 57) && (keyCode < 96 || keyCode > 105)) {
            event.preventDefault();
            return false;
        }
        return true;
    },

    parseInteger : function(value: string, defaultValue: number = 0): number {
        const parsedValue = parseInt(value);
        if (!isNaN(parsedValue)) {
            return parsedValue;
        } else {
            console.error(`Invalid integer value: ${value}`);
            throw new Error(`Invalid integer value: ${value}`);            
            // Alternatively, you can return a default value 0 here if you want to handle it differently
            // return defaultValue; 
        }
    },

    parseDate : function(value: any, defaultValue: Date = new Date()): Date {
        const parsedDate = dayjs(value);
        if (parsedDate.isValid()) {
            return parsedDate.toDate();
        } else {
            console.error(`Invalid date value: ${value}`);           
            throw new Error(`Invalid date value: ${value}`);
            // Alternatively, you can throw an default value here if you want to handle it differently
            // return defaultValue;
            
        }
    },

    getCurrentUTCDateTime : function(): Date {
        // Extend dayjs with UTC plugin
        dayjs.extend(utc);

        // Get current UTC time with offset
        const utcWithOffset = dayjs.utc();

        // Format the time with offset        
        const iso8601WithOffset = utcWithOffset.toISOString();
        console.log("UTC Time with Offset:", iso8601WithOffset);
        
        return utcWithOffset.toDate();
    },

    getDeviceType: function (): string  {
        if (typeof window === 'undefined')
            return 'unknown'

        const md = new MobileDetect(window.navigator.userAgent);

        if (md.mobile()) {
          return 'mobile';
        } else if (md.tablet()) {
          return 'tablet';
        } else {
          return 'desktop';
        }    
    },
}
export { Tools }