import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Typography,AppBar, Box,Toolbar, Grid, IconButton,Link,Hidden, styled} from '@mui/material';
import { StoreContext } from "../../StoreContext";
import VecLogo from '../../../static/images/vec-logo.svg';
import VecHome from '../../../static/images/home.svg';

type HeaderProps = {
    title: string;
  };

  export const navigationItems = {
    VECHome: {
        label: 'VEC Home',
        icon: VecHome,
    }
};

const StyleRoot = styled("div")(({ theme }) => ({
    "& .titleContainer": {
        lineHeight: '64px',
        width: '100%',
        display: 'block',
        textAlign: 'center',
        [theme.breakpoints.down('xs')]: {
            lineHeight: '56px',
        }
    },
    "& .vecLogo": {
        width: '31px',
        height: '31px',
        objectFit: 'contain',
        transform: 'translateY(15%)'
    },  
    "& .appBarTitle": {
        fontSize: '22px',
        fontWeight: 'bold',
        display: 'inline-block',
        marginLeft: '16px',
        color: theme.palette.secondary.main
    },
    "& .homeButton": {
        verticalAlign: 'middle',
        paddingTop: '5px',
        paddingLeft: '0px',
        boderRadius: '0px',
    },
    "& .homeLabel": {
        fontSize: '18px',
        fontWeight: 'bold',
        lineHeight: '1.67',
        letterSpacing: '0.4px',
        paddingLeft: '10px',
        width: '150px'
    }
  }));


const AppHeader = observer(({title}: HeaderProps) => {
    const { onlineRedirectionStore } = useContext(StoreContext);
    const vecWebSiteBackUrl = onlineRedirectionStore.websiteBackUrl;
    
    const vecHomeClickHandler = () => {
        console.log("API call new" + vecWebSiteBackUrl);
        window.location.assign(vecWebSiteBackUrl);     
    };
       
    return (
        <Box sx={{  }}>
        <StyleRoot>
        <AppBar>
            <Toolbar className="appToolbar">
                <Grid container alignItems='center'>
                    <Grid item lg={2} sm={2} xs={1}>
                        <IconButton
                            name='VEC Home button'
                            className="homeButton"
                            color="inherit"
                            aria-label="Menu"
                            id="btn-header-menu"
                            onClick={vecHomeClickHandler}>
                            <img src={navigationItems.VECHome.icon} alt='Home Icon' />
                        </IconButton>
                        <Hidden only={['sm', 'xs']}>
                            <Link href={vecWebSiteBackUrl} color='secondary'><span className="homeLabel">VEC Home</span></Link>
                        </Hidden>
                    </Grid>
                    <Grid item lg={8} sm={9} xs={10}>
                        <div className="titleContainer">                
                            <img className="vecLogo" src={VecLogo} alt="VEC logo" />
                            <Hidden only={['xs']}>
                                <Typography className="appBarTitle">{title}</Typography>
                            </Hidden>
                        </div>
                    </Grid>
                    <Grid item lg={2} sm={1} xs={1}></Grid>
                </Grid>
            </Toolbar>
        </AppBar>
        </StyleRoot>
        </Box>
    );
  });
  
  export default AppHeader;
