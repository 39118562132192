import React, { useContext } from "react";
import { Input, TextField } from "@mui/material";
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
import { observer } from "mobx-react";

interface AddressTextBoxProps {
  elementId: string; // element id
  googleApiKey: string; // Google API key
  placeHolderText: string;
  disabled?: boolean;
  onSearchChange: (place: google.maps.places.PlaceResult) => void; // Callback function type
  onTextInputChange: (value: string) => void;
}

const VecAddressLookup: React.FC<AddressTextBoxProps> = observer(({ elementId,googleApiKey,placeHolderText, disabled = false, onSearchChange,onTextInputChange }) => { 
  const handleSearchChange = (place: google.maps.places.PlaceResult) => {    
    if (onSearchChange) {
      onSearchChange(place);
    }
  };

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {   
    const addressTextBox = document.getElementById(`txtAddressLookup-${elementId}`) as HTMLInputElement;
    if (addressTextBox) {
      onTextInputChange(addressTextBox.value);
    } 
  }; 

  const { ref: materialRef } = usePlacesWidget({
    apiKey: googleApiKey,
    onPlaceSelected: (place: google.maps.places.PlaceResult) => handleSearchChange(place),
    inputAutocompleteValue: "country",
    options: {
      componentRestrictions: { country: "au" },
      fields: ["address_components", "geometry"],
      types: ["address"],
    },
  });

  return (
    <div>
      <header>
        <div style={{ display: "none" }}>
          <Input
            fullWidth
            color="secondary"
            inputComponent = {AutocompleteInput}
          />
        </div>
        <div>
          <TextField
            id={`txtAddressLookup-${elementId}`}
            name={`txtAddressLookup-${elementId}`}
            fullWidth
            required            
            disabled={disabled}
            variant="outlined"
            placeholder={placeHolderText}
            inputProps={{
              maxLength: 500,
            }}
            inputRef={materialRef}
            onChange={handleInput}
          />
        </div>
      </header>
    </div>
  );
});

interface AutocompleteInputProps {
  vecGoogleMapApiKey: string;
}

// AutocompleteInput component with forwardRef
const AutocompleteInput = React.forwardRef<HTMLInputElement, AutocompleteInputProps>(({ vecGoogleMapApiKey },ref) => (
  <Autocomplete
    apiKey={"AIzaSyArWTQOaGGO4zYzsGZzaNJjkNigbFMF7dk"}
    sessiontoken={"true"}
    style={{ width: "100%" }} // Ensure the width covers the input area
    options= {{
      componentRestrictions: { country: "au" },
      fields: ["address_components", "geometry"],
      types: ["address"],
    }}
    ref={ref}   
    defaultValue=""
    // {...props} // Spread props
  />
));

export default VecAddressLookup;
