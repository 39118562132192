import { blue } from "@mui/material/colors";
import type {} from '@mui/x-date-pickers/themeAugmentation';
import '../../static/css/ora-app.css';

import {
  StyledEngineProvider,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import React from "react";
import { colors } from "@mui/material";

const primaryRed = "#a30134";
const responsiveFontSize = ({ theme, desktop, mobile }) => ({
  fontSize: desktop,
  [theme.breakpoints.down("sm")]: {
    fontSize: mobile,
  },
});
const theme = createTheme({
  components: {
    MuiAppBar: {
      styleOverrides: {
          root: {
              backgroundColor: '#ffe3ea !important',
              color: '#0E3A6C !important',
              '& svg': {
                  color: '#0E3A6C !important'
              }
          },
      },
      defaultProps: {
          color: "inherit",
      },
    }, 
    MuiGrid:{
      styleOverrides: {
        root: {         
          
        },
        item: {
          paddingTop:'20px !important',          
        },
      },
    },

    MuiRadio:{
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#0e3a6c',
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#0e3a6c',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          padding: '12px 12px',
          minWidth: '160px',
          fontSize: '18px',
          cursor: 'pointer',
          color: '#a30134',                
          borderColor: '#a30134',
          '&:hover': {
             backgroundColor: '#0e3a6c !important',
             borderColor: '#0e3a6c',
             color: '#ffffff',
             cursor: 'pointer'
          }
        },
      },
    },    
    MuiLink:{
      styleOverrides: {
        root: {
            cursor: 'pointer !important',
            // fontWeight: 600,
            color: '#0e3a6c',
            '&:hover': {
                 color: '#a30134',
             },
            fontFamily: 'Poppins,Roboto,Arial,sans-serif',
            textDecoration: 'none'
        }
      }
    },  
    MuiSvgIcon: {
      styleOverrides: {
        root: {
            height: '1em',
            width: '1em'
        }
      }
    },   
    MuiStack: {
      styleOverrides: {
        root: {
          paddingTop: '10px !important',
          paddingBottom: '10px !important',
        }
      }
    }, 
    MuiSelect: {
      styleOverrides: {
        select: {                
            //textTransform: 'capitalize',
            fontFamily: 'Poppins,Roboto,Arial,sans-serif'
        }
      }
    },    
    MuiIconButton: {
      styleOverrides: {
        root: {},
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: '#A30134 !important',
          margin: '0',
          fontSize: '18px',
          marginTop: '3px',
          textAlign: 'left',
          fontFamily: 'Poppins,Roboto,Arial,sans-serif',
          fontWeight: 400,
          lineHeight: '1.66'
        },
      },
    },

    MuiDialogActions: {
      styleOverrides: {
        root: {          
          margin: '10px',         
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {          
          paddingBottom: '10px',
          paddingTop: '10px'
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {          
          paddingBottom: '0px',
        },
      },
    },
   
    MuiTypography: {  
      styleOverrides:{          
      h1: {
          fontSize: '46px',
          fontWeight: 700,
          lineHeight: '1.2',
          color: '#0E3A6C'
      },
      h2: {
          fontSize: '34px',
          fontWeight: 700,
          fontStretch: 'normal',
          color: '#0E3A6C',
          lineHeight: '1.2',
          marginTop: '30px',
          marginBottom: '30px'
      },
      body2: {
          fontSize: '18px',
          lineHeight: '1.8'
      },

      h3: {
          fontSize: '18px',
          fontWeight: 300,
          fontStretch: 'normal',
          color: '#0E3A6C'
      },
      h4: {
          fontSize: '20px',
      },
      h5: {
          fontSize: '16px',
      },
      h6: {
          fontSize: '14px',
      },                      
      subtitle1: {
          fontSize: '14px',
          textTransform: 'uppercase',
          weight: 700,                
      },
      subtitle2: {
          fontSize: '14px',
          textTransform: 'uppercase',                
      },
      body1: {
          fontSize: '16px',                
          lineHeight: '1.5',
          color: '#666',
          paddingTop: '10px',
          paddingBottom: '10px',
          fontFamily: 'Poppins,Roboto,Arial,sans-serif',
          fontWeight: '400'
      },
      alignCenter: {                
          margin: '-10px'
      }
  }
  },          
  MuiFormControlLabel: {
        styleOverrides: {
        root : {
            alignItems: 'unset',
            marginRight: '20px',
            display: 'flex',
            cursor: 'normal',
            textTransform: 'none'
        },
        labelPlacementTop : {
            marginLeft: '0px'          
        },
        label : {
            cursor: 'auto',
            fontSize: '18px',
            lineHeight: '1.8',
            alignItems: 'le'
        },
        asterisk :{
          color: '#e00'         
        }
      }
  },
  
  MuiSnackbarContent: {
      styleOverrides: {
        root: {
          minWidth: "360px",
        },
      },
  }, 
  MuiTooltip:{
    styleOverrides:{
      tooltip:{
        backgroundColor: '#FFFFFF !important',
        color: '#666 !important',
        fontFamily: 'Poppins,Roboto,Arial,sans-serif',
        border: '1px solid'
      }
    }
  }
},
  
  typography: {    
    fontFamily: [
        'Poppins',
        'Roboto',
        'Arial',
        'sans-serif'
    ].join(',')
  },
  palette: {
    primary: {
      light: '#da475d',
      main: '#a30134',
      dark: '#84002a',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#b6d7ec',
      main: '#0e3a6c',
      dark: '#0e253a',
      contrastText: "#f7f7f7",
    },
    error: {
      main: "#d32f2f",
    },
    info: {
      main: "#1976d2",
    },
    grey: {
      [200]: "#f5f5f5",
      [300]: "#c2c2c2",
      [400]: "#757575",
      [500]: "#6f6f6f",
    },
    background: {
      default: "#fafafa",
    },
  },
});

const VecTheme = ({ children }: VecThemeProps) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </StyledEngineProvider>
);
type VecThemeProps = {
  children: React.ReactNode;
};

export default VecTheme;
