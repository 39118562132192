import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Box,Typography,Grid, FormControlLabel, Checkbox,FormHelperText,Button,Tooltip } from '@mui/material';
import { StoreContext } from "../../StoreContext";
import CancelButton from './cancel-button';
import SubmitButton from './submit-button';
import Turnstile, { useTurnstile } from "react-turnstile";
type HeaderProps = {
    title: string;
  };

const DeclarationSection = observer(({ title }: HeaderProps) => {
    const { onlineRedirectionStore } = useContext(StoreContext);    
    const { isEntitledDeclareChecked, isTrueCorrectDeclareChecked, 
            isDeclarationChecked,            
            showErrors  } = onlineRedirectionStore;   
    const handleEntitledDeclareChange = (e) => {onlineRedirectionStore.setEntitledDeclarationCheck(e.target.checked);}
    const handleTrueCorrectDeclareChange = (e) => {onlineRedirectionStore.setTrueCorrectDeclarationCheck(e.target.checked);}
    const turnstile = useTurnstile();          
    return (
      <Box component="section">
        {/* <StyleRoot> */}        
        <Typography variant="h2">{title}</Typography>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Box sx={{ }}> 
            <Typography variant='body2'><span style={{ color: '#e00',fontSize :'16px' }}>*&nbsp;</span>I declare that:</Typography>               
            <FormControlLabel control={<Tooltip                                       
                                        title="Please check this box if you want to proceed.">
                                        <Checkbox sx={{ }}
                                        name="chkEntitledDeclaration" 
                                        checked={isEntitledDeclareChecked }
                                        onChange={handleEntitledDeclareChange}
                                        />
                                      </Tooltip>}
                                label="I am entitled to apply for a redirection of my ballot pack to the above claimed address."
                            />
            </Box>
            <Box sx={{ }}>              
            <FormControlLabel className={""}
                            control={ <Tooltip                                       
                                        title="Please check this box if you want to proceed.">
                                        <Checkbox sx={{ }}
                                        name="chkTrueCorrectDeclaration" 
                                        checked={isTrueCorrectDeclareChecked }
                                        onChange={handleTrueCorrectDeclareChange}
                                        />
                                      </Tooltip>}
                                label="The information provided above is true and correct. I understand that giving false or misleading information is a serious offence."
                            />
                            <FormHelperText>{showErrors && !isDeclarationChecked ? "Please check the boxes": ""}</FormHelperText>
            </Box>
          </Grid> 
          <Grid item xs={12}>
            <Box sx={{ }}> 
                <div className={"cf-turnstile"} data-sitekey={onlineRedirectionStore.CLOUD_FLARE_SITE_KEY} data-theme="light" data-callback="turnstileResponseHandler"></div>                
                <Turnstile
                sitekey={onlineRedirectionStore.CLOUD_FLARE_SITE_KEY}
                onVerify={(token) => {
                  // @ts-ignore
                  window.turnstileResponseHandler(token);
                }}
                />
            </Box>
          </Grid>                    
          <Grid item xs={12}>
            <Box sx={{ }}>              
            <Grid container>
              <Grid item xs={12} sm={12} md={6} lg={6} className={"btnContainer"}>               
                <CancelButton/>
              </Grid>                                                                                
              <Grid item xs={12} sm={12} md={6} lg={6} className={"btnContainer"}>               
                <SubmitButton/>
              </Grid>
            </Grid>
            </Box>
          </Grid>
        </Grid>
        {/* </StyleRoot> */}
      </Box>

    );
  });
  
  export default DeclarationSection;