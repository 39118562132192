import React, { useContext } from "react";
import { StoreContext } from "../../StoreContext";
import { observer } from "mobx-react";
import { Box,Typography,Grid, FormControlLabel, TextField,FormHelperText,Hidden,styled} from '@mui/material';
//import AddressLookup from './address-lookup';
import AddressLookup from '../vec-address-lookup';
import Autocomplete from '@mui/material/Autocomplete';


type HeaderProps = {
    title: string;
};

const EnrolledAddress = observer(({ title }: HeaderProps) => {
  const { onlineRedirectionStore } = useContext(StoreContext);    
    const { 
      councilList, 
      enrolledAddress,
      council,
      ward, 
      isEnrolledAddressValid, 
      isInputCouncilValid,
      isCouncilValid, 
      councilHelperText,
      enrolledAddressHelperText,
      dontNeedToVoteHelperText,
      showErrors
      } = onlineRedirectionStore; 

    const autoCompleteValue=new Date().getTime();
    const onEnrolledTextChange = (value) => { onlineRedirectionStore.setEnrolledAddress(value); onlineRedirectionStore.setIsGoogleAddressSelected(false); }  
    const handleWardChange = (e) => {onlineRedirectionStore.setWard(e.target.value);} 
    const onSearchChange = (place) => { onlineRedirectionStore.setCouncilWard(place);onlineRedirectionStore.setGoogleSelectedEnrolledAddress(); onlineRedirectionStore.setIsGoogleAddressSelected(true);};
    const handleCouncilChange = (e, value) => {
      const councilName = value?.electorateName ?? '';
      const electorateId = value?.electorateId ?? '';  
      onlineRedirectionStore.setCouncil(councilName);
      onlineRedirectionStore.setElectorateId(electorateId);
    }
  

  return (
    <Box component="section">
      <Typography variant="h2">{title}</Typography>
      <Grid container spacing={5}> 
          <Grid item xs={12}>
            <Box sx={{ }}>
              <Typography variant='body2'>This is the address for which you are entitled to apply for a redirection of your ballot pack.</Typography> 
              <Hidden only={['lg','xl']}>
                <span className="labelText">Don't know which council and ward your address is in?</span> 
                <span>Go to&nbsp;<a href = "https://maps.vec.vic.gov.au" rel="noopener noreferrer" target = "_blank">Find my electorate</a>&nbsp;to check.</span>
              </Hidden>
              <Hidden only={['md','sm','xs']}>
                <span className="labelText">Don't know which council and ward your address is in? Go to&nbsp;{<a href = "https://maps.vec.vic.gov.au" rel="noopener noreferrer" target = "_blank">Find my electorate</a>}&nbsp;to check.</span> 
              </Hidden>
              <FormControlLabel control = {   
                    <AddressLookup 
                      elementId="enrolledAddress"
                      googleApiKey={onlineRedirectionStore.GOOGLE_API_KEY} 
                      placeHolderText="Enter Enrolled Address" 
                      onSearchChange={onSearchChange}
                      onTextInputChange={onEnrolledTextChange}
                      disabled={false}>                      
                    </AddressLookup> 
                } label={<span>Full enrolled address{''}<span style={{ color: '#e00',fontSize :'16px' }}>&nbsp;*</span></span>} labelPlacement='top' />   
                <FormHelperText>{showErrors && !isEnrolledAddressValid ? enrolledAddressHelperText: ""}</FormHelperText>  
                <FormHelperText>{showErrors && isEnrolledAddressValid && !isInputCouncilValid ? dontNeedToVoteHelperText: ""}</FormHelperText>            
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ }}>
                <FormControlLabel control = {                  
                    <Autocomplete
                        id="txtAutoCouncil"
                        freeSolo
                        value={council}
                        options={councilList} 
                        getOptionLabel={(option) => option?.electorateName || council}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                margin="normal"
                                variant="outlined"
                                id={`txtCouncil-${autoCompleteValue}`}
                                name={`txtCouncil-${autoCompleteValue}`}
                                required
                                inputProps={{
                                    ...params.inputProps,
                                    autoComplete: `txtCouncil`,
                                    placeholder: "Select Council"
                                }}
                            />
                        )}
                        onChange={handleCouncilChange}
                        renderOption={(props, option) => (
                          <li {...props}>{option.electorateName}</li>
                        )}
                    />           
                } label={<span>Council{''}<span style={{ color: '#e00',fontSize :'16px' }}>&nbsp;*</span></span>} labelPlacement='top'/>
                <FormHelperText>{showErrors && !isCouncilValid ? councilHelperText: ""}</FormHelperText>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box sx={{  }}>
                  <FormControlLabel control = {
                      <TextField
                        id="txtWard"
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        placeholder="Enter Ward"
                        inputProps={{                                            
                        }}
                        value={ward}
                        onChange={handleWardChange}
                      />
                    } label={<span>Ward (optional)</span>} labelPlacement='top'/>
            </Box>
          </Grid>             
      </Grid>
    </Box>
  );
});
  
export default EnrolledAddress;
