import React from "react";
import { Typography, Container, Grid, Link, Box, Paper} from '@mui/material';
import TorresImg from '../../../static/images/torres-strait-islander-flag.svg';
import AustralianImg from '../../../static/images/australian-aboriginal-flag.svg';
import { yellow } from "@mui/material/colors";

class Footer extends React.Component {
    
    render() {        
        return (
            <Paper sx={{marginTop: 'calc(5% + 5px)',
            width: '100%',
            position: 'relative',
            bottom: 0,
            backgroundColor: '#f2f2f2 !important'
            }} component="footer" square variant="outlined">
              <Grid container sx={{ margin:{ xs: '8px',sm:'15px' },paddingRight:'10px'}}>
                  <Grid item lg={2} md={2} sm={1}> &nbsp;</Grid>
                  <Grid item lg={8} md={8} sm={10} xs={12} className="container">
                      <Grid container sx={{ backgroundColor: '#f2f2f2 !important'}} spacing={1}>             
                        <Grid item xs={4}>
                            <Link href="https://www.vec.vic.gov.au/privacy">Privacy</Link>
                        </Grid>
                        <Grid item xs={4}>
                            <Link href="https://www.vec.vic.gov.au/legal">Legal</Link>
                        </Grid>
                        <Grid item xs={4}>
                            <Link href="https://www.vec.vic.gov.au/accessibility">Accessibility</Link>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>Authorised by S. Bluemmel, Electoral Commissioner, 530 Collins Street, Melbourne, Victoria.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography>The Victorian Electoral Commission acknowledges and pays respect to the Traditional Owners of the land.</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <img alt='Torres Strait islander flag' src={TorresImg} />
                                &nbsp;&nbsp;
                            <img alt='Australian aboriginal flag' src={AustralianImg} />
                        </Grid>
                        <Grid item xs={12}>&nbsp;</Grid>
                        <Grid item xs={12}>
                            <Typography>© State of Victoria (Victorian Electoral Commission) {new Date().getFullYear()}</Typography>
                        </Grid>
                      </Grid>
                  </Grid>
                  <Grid item lg={2} md={2} sm={1}>&nbsp;</Grid>
              </Grid>
            </Paper>
          );
    }
}

export default Footer;
