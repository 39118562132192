import React, { useContext } from "react";
import { StoreContext } from "../../StoreContext";
import { observer } from "mobx-react";
import { Box,Typography,Grid,RadioGroup, Radio,FormControlLabel, TextField,FormHelperText,Hidden,styled} from '@mui/material';
import AustralianPostalAddress from './australian-postal-address';
import OverseasPostalAddress from './overseas-postal-address';

type HeaderProps = {
    title: string;
  };

const PostalAddress = observer(({ title }: HeaderProps) => {
  const { onlineRedirectionStore } = useContext(StoreContext);
  const {postalAddressTypeId , showErrors, isPostalAddressValid, postalAddressHelperText} = onlineRedirectionStore;
        const showHideAUAddress = postalAddressTypeId == "1" ? true : false;
        const showHideOverseasAddress = postalAddressTypeId == "2" ? true : false;
  const  handleAddressTypeChange = event => {
    onlineRedirectionStore.setPostalAddressType(event.target.value)
  };

  return (
    <Box component="section">
      <Typography variant="h2">{title}</Typography>
      <Grid container spacing={5}>
          <Grid item xs={12}>
            <Box sx={{ }}>             
              <Typography variant='body2'>Tell us where you would like us to post your ballot pack.</Typography>  
              <RadioGroup
                            aria-labelledby="your postal address"
                            name="postal-address-types"
                            value={postalAddressTypeId}
                            onChange={handleAddressTypeChange}>
                            <FormControlLabel value="0" control={<Radio />} label={<span className="radioText">The enrolled address I entered above</span>} />
                            <FormControlLabel value="1" control={<Radio />} label={<span className="radioText">Other Australian postal address</span>} />
                            <FormControlLabel value="2" control={<Radio />} label={<span className="radioText">Overseas postal address</span>} />
                        </RadioGroup>
                        <FormHelperText>{showErrors && !isPostalAddressValid ? postalAddressHelperText: ""}</FormHelperText>  
            </Box>
            <Box sx={{ display: (postalAddressTypeId == "1" || postalAddressTypeId == "2")? "block": "none"}}>
              <Grid item xs={12}>
                  { showHideAUAddress && 
                    <AustralianPostalAddress 
                      title="Australian postal address"/>
                  }
                  { showHideOverseasAddress && 
                    <OverseasPostalAddress 
                      title="Overseas postal address"/>
                  }
              </Grid>
            </Box>
          </Grid>
      </Grid>
    </Box>
  );
});
  
export default PostalAddress;
