import { fetchGet, fetchPost, getResponseText } from "../../api";
import {
  ElectorateSummary,
  ElectionSummary,
  Council
} from "../../types/common";

import {
  OnlineRedirectionApplicationRequest,
  OnlineRedirectionApplicationResponse
} from "../../types/online.redirection.type";

class OnlineRedirectionService { 

  checkService() {
    return "OK";
  } 

  getGoogleMapApiKey() {
    const url = '/api/onlineredirection/googlemapapikey';
    return fetchGet<string>(url);
  } 

  getCloudFlareSiteKey() {
    const url = '/api/onlineredirection/cloudflaresitekey';
    return fetchGet<string>(url);
  } 

  getWebSiteBackUrl() {
    const url = '/api/onlineredirection/websitebackurl';
    return fetchGet<string>(url);
  } 

  getClosestElection() {
    const url = '/api/onlineredirection/closestelection';
    return fetchGet<ElectionSummary>(url);
  }

  getElectoratesSummary(latitude, longitude) {
    const url = `/api/onlineredirection/electoratessummary?latitude=${latitude}&longitude=${longitude}`;
    return fetchGet<ElectorateSummary>(url);
  } 

  getCouncils(electionId){
    const url = `/api/onlineredirection/relatedelections?electionId=${electionId}`;
    return fetchGet<Council[]>(url);
  }

  postOnlineRedirectionApplication(application: OnlineRedirectionApplicationRequest){
    const url = `/api/onlineredirection`;
    return fetchPost<OnlineRedirectionApplicationRequest,OnlineRedirectionApplicationResponse>(url,application);
  }

}

const onlineRedirectionService = new OnlineRedirectionService();

export default onlineRedirectionService;