import React from 'react';
import { Container } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import PageHeader from './header';
import PageFooter from './footer';
import Content from './content';

function OraPageLayout(props) {
  return (
    <>
      <CssBaseline />
      <Container disableGutters maxWidth={false}>
        <main>
          <PageHeader title='Victorian Electoral Commission' />
          {props.children}
          <PageFooter/>
        </main>
      </Container>
    </>
  );
}

export default function App() {
  return (
    <>
      <OraPageLayout>
        <Content />
      </OraPageLayout>
    </>
  );
}