import React, { useContext } from "react";
import { observer } from "mobx-react";
import { StoreContext } from "../../StoreContext";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


const SubmitButton = observer(() => {
    const { onlineRedirectionStore } = useContext(StoreContext); 
    
    const success = onlineRedirectionStore.submitSuccess;
    const dialogTitle = success ? "Submitted" : "Failed";    
    const confirmButtonText = "Close";
   
    const gridItemStyle = {
      paddingTop:{ xs:'10px !important', md:'0px !important'},
      PaddingLeft:'25px !important',
      PaddingRight:'25px !important',
    };
  
    const dialogActionStyle = {
      paddingLeft: {xs: '10px !important', md: '80px !important', lg: '100px !important'},
      paddingRight: {xs: '10px !important', md: '80px !important', lg: '100px !important'}
    } 

    const submitBtnStyle = {
      padding: '12px 12px',
      minWidth: '160px',
      fontSize: '18px',
      cursor: 'pointer',
      color: '#a30134',
      borderColor: '#a30134'
    }

    const submitBtnDisabledStyle = {
        padding: '12px 12px',
        minWidth: '160px',
        fontSize: '18px',
        cursor: 'pointer',
        color: '#0e253a',
        borderColor: '#39444F !important',
        Border:'100px !important'
    }    
      
    
    const handleClickOpen = (e) => {
      e.preventDefault();
      onlineRedirectionStore.submitOnlineRedirectionApplication();
    };
  
    const handleCancel = () => {
      onlineRedirectionStore.setOpenSubmitButtonDialogBox(false);
    };
  
    const handleConfirm = () => {      
      if(!onlineRedirectionStore.submitSuccess){
        onlineRedirectionStore.setOpenSubmitButtonDialogBox(false);
        window.location.reload();
      }        
      else {
        window.location.replace(onlineRedirectionStore.websiteBackUrl);
      }        
    };

    const handleBackdropClick = (event) => {
      // Prevent closing the dialog when clicking on the backdrop
      event.stopPropagation();
    };

    let isDisabled = !(onlineRedirectionStore.isEntitledDeclareChecked && onlineRedirectionStore.isTrueCorrectDeclareChecked);
    const submitButtonStyle = isDisabled ? submitBtnDisabledStyle : submitBtnStyle;
  
    return (
      <React.Fragment>
         <Button variant="outlined" aria-label="Submit" disabled={isDisabled} sx={submitButtonStyle} onClick={handleClickOpen}>
            Submit
        </Button>
        <button type="submit" 
          style={{position: 'absolute',
          width: '1px',
          height: '1px',
          margin: '-1px',
          padding: '0',
          overflow: 'hidden',
          clip: 'rect(0, 0, 0, 0)',
          border: '0'}}
          aria-hidden="true"
        >       
        </button>        
        <Dialog 
          sx={{'& .MuiBackdrop-root': { pointerEvents: 'none' }}}
          fullScreen={false}
          open={onlineRedirectionStore.openSubmitButtonDialogBox}
          onClose={handleCancel}
          disableEscapeKeyDown={true}
          aria-labelledby="Online redirection of ballot pack application submission result"
          maxWidth={'lg'}
          onClick={handleBackdropClick}
        >
          <DialogTitle id="online-redirection-form-dialog-submit-button"
                        sx={{ fontSize: { xs: '1.4rem', md: '1.6rem' },color:'#0E3A6C' }} >
            {dialogTitle}
          </DialogTitle>
          {!success && <DialogContent>
            <DialogContentText variant='body2' sx={{color:'#3c3c3b'}}>
              Submission failed.Please try again.              
            </DialogContentText>
          </DialogContent>
          }
          {success && <DialogContent>
            <DialogContentText variant='body2' sx={{color:'#3c3c3b'}}>
            You have successfully submitted your ballot pack redirection application.         
            </DialogContentText>
            <DialogContentText variant='body2' sx={{color:'#3c3c3b'}}>  
                Your reference number is <strong style={{ color: '#0E3A6C', fontSize:'1.4rem' }}>{onlineRedirectionStore.trackingNumber}</strong>              
            </DialogContentText>
            <DialogContentText variant='body2' sx={{color:'#3c3c3b'}}>              
              We will contact you if we need any other information to process your request.
            </DialogContentText>
          </DialogContent>
          }         
          {success && <DialogActions sx={dialogActionStyle}>
            <Grid container spacing={1} justifyContent="center" sx={{ paddingTop:'5px' }}>            
              <Grid item xs={12} md={6} sx={gridItemStyle}>
                <Button fullWidth variant='outlined'                     
                    onClick={handleConfirm} 
                    autoFocus>
                  {confirmButtonText}
                </Button>
              </Grid>              
            </Grid>
          </DialogActions>}
          {!success && <DialogActions sx={{marginLeft:'0px !important'}}>
            <Grid container spacing={1} justifyContent="center" sx={{ paddingTop:'5px' }}>            
              <Grid item xs={12} md={6} sx={gridItemStyle}>
                <Button fullWidth variant='outlined' sx={{paddingLeft:'0px !important'}}
                    onClick={handleConfirm} 
                    autoFocus>
                  {confirmButtonText}
                </Button>
              </Grid>              
            </Grid>
          </DialogActions>}
        </Dialog>
      </React.Fragment>
    );   
  });
  
export default SubmitButton;
