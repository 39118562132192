import React, { useContext } from "react";
import { observer } from "mobx-react";
import { Box,Typography,Grid, FormControlLabel, TextField,Radio,Link,styled} from '@mui/material';
import AddressLookup from '../vec-address-lookup';
import { StoreContext } from "../../StoreContext";
import Checkbox from '@mui/material/Checkbox'
import {Tools} from '../../utils/tools';


type HeaderProps = {
    title: string;
  };

const AustralianPostalAddress = observer(({ title }: HeaderProps) => {
    const { onlineRedirectionStore } = useContext(StoreContext);    
    const {postalAddress,
      postalPropertyName,
      postalFlatUnit,
      postalStreetNumber,
      postalStreetName,
      postalSuburb,
      postalState,
      postalPostCode,
      isPostalStreetNameValid,
      isPostalSuburbValid,
      isPostalStateValid,
      isPostalPostCodeValid,
      showErrors,
      unableToFindMyAddress,
      submitPostalAddressTypeId,
      disablePostalPropertyName,
      disablePostalFlatUnit,
      disablePostalStreetNumber,
      disablePostalStreetName,
      disablePostalSuburb,
      disablePostalState,
      disablePostalPostCode,
      } = onlineRedirectionStore; 
      
      const handleAddressTypeChange = (e) => {onlineRedirectionStore.setPostalAddressType(e.target.value)};
      const handlePostalPropertyNameChange = (e) => {onlineRedirectionStore.setPostalPropertyName(e.target.value);}
      const handlePostalFlatUnitChange = (e) => {onlineRedirectionStore.setPostalFlatUnit(e.target.value);}
      const handlePostalStreetNumberChange = (e) => {onlineRedirectionStore.setPostalStreetNumber(e.target.value);}
      const handlePostalStreetNameChange = (e) => {onlineRedirectionStore.setPostalStreetName(e.target.value);}
      const handlePostalSuburbChange = (e) => {onlineRedirectionStore.setPostalSuburb(e.target.value);}
      const handlePostalStateChange = (e) => {onlineRedirectionStore.setPostalState(e.target.value);}
      const handlePostalPostCodeChange = (e) => {onlineRedirectionStore.setPostalPostCode(e.target.value);}
      const onSearchChange = (place) => { onlineRedirectionStore.setAustralianPostalAddressFields(place)};         
      const onPostalAddressChange = (value) => {onlineRedirectionStore.setPostalAddress(value)};

      const onUnableToFindMyAddressChecked = (e) => {
        onlineRedirectionStore.setUnableToFindMyAddress(e.target.checked);
        onlineRedirectionStore.disablePostalAddressFields(!e.target.checked);
        clearAutoCompleteBox()
      }
      
      const clearAutoCompleteBox = () => {
        const addressTextBox = document.getElementById(`txtAddressLookup-auPostalAddress`) as HTMLInputElement;
        if (addressTextBox && !unableToFindMyAddress) {          
          addressTextBox.value = "";          
        } 
      } 

      const handlePostalPostCodeKeyDown = (e) => { return Tools.validateNumber(e);};
      const isDirty = showErrors && submitPostalAddressTypeId == "1"; //AU
    
    return (
      <Box component="section">
        {/* <StyleRoot> */}        
        <Typography variant="h2">{title}</Typography>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Box sx={{ }}>              
              <FormControlLabel control = { 
                  <AddressLookup 
                    elementId="auPostalAddress"
                    googleApiKey={onlineRedirectionStore.GOOGLE_API_KEY} 
                    placeHolderText="e.g. 21 Lygon Street, Caulfield South VIC 3162" 
                    onSearchChange={onSearchChange}
                    onTextInputChange={onPostalAddressChange}
                    disabled={unableToFindMyAddress}>                      
                  </AddressLookup>                 
                  } label= "Australian postal address" labelPlacement='top' />
            </Box>            
          </Grid> 
          <Grid item xs={12}>
            <Box sx={{ }}>              
              <FormControlLabel value="0" control={
                <Checkbox checked={unableToFindMyAddress} onChange={onUnableToFindMyAddressChecked} />
                } label={<span>Unable to find my address or using a PO Box address - enter manually</span>} />
            </Box>
          </Grid>        
          <Grid sx={{ paddingTop:"0px !important" }} item xs={12} sm={6}>
            <Box>
              <FormControlLabel control = {
                                    <TextField
                                        id="txtPropertyName"                                     
                                        variant="outlined"
                                        placeholder="e.g. Rippon House"
                                        inputProps={{
                                            maxLength: 100
                                          }}
                                        value={postalPropertyName}
                                        disabled={disablePostalPropertyName}
                                        onChange={handlePostalPropertyNameChange}                                       
                                    />
                                } label="Property name" labelPlacement='top'/>
            </Box>
          </Grid>          
          <Grid sx={{ paddingTop:"0px !important" }} item xs={12} sm={6}>
            <Box >
            <FormControlLabel control = {
                                    <TextField
                                        id="txtFlatUnit"                                      
                                        variant="outlined"
                                        placeholder="e.g. Unit 505G"
                                        inputProps={{
                                            maxLength: 50
                                          }}
                                        value={postalFlatUnit}
                                        disabled={disablePostalFlatUnit}
                                        onChange={handlePostalFlatUnitChange}  
                                    />
                                } label="Flat/unit" labelPlacement='top'/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ }}>
            <FormControlLabel control = {
                                        <TextField
                                            id="txtStreetNumber"                                          
                                            variant="outlined"
                                            placeholder="e.g. 260"
                                            inputProps={{
                                              maxLength: 45
                                            }}
                                            value={postalStreetNumber}
                                            disabled={disablePostalStreetNumber}
                                            onChange={handlePostalStreetNumberChange}                                            
                                        />
                                    } label="Street number" labelPlacement='top' />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{  }}>
            <FormControlLabel control = {
                                    <TextField
                                        id="txtStreetName"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="e.g. Glenhuntly Road or PO Box 818"
                                        required
                                        inputProps={{
                                            maxLength: 40
                                          }}
                                          value={postalStreetName}
                                          disabled={disablePostalStreetName}
                                          onChange={handlePostalStreetNameChange}
                                          helperText={showErrors && isDirty && !isPostalStreetNameValid ? "Please provide street name/PO Box" : ""}                                          
                                    />
                                } label="Street name/PO Box" labelPlacement='top'/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{  }}>
            <FormControlLabel control = {
                                    <TextField
                                        id="txtSuburb"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="e.g. Elsternwick"
                                        required
                                        inputProps={{
                                            maxLength: 45
                                          }}
                                        value={postalSuburb}
                                        disabled={disablePostalSuburb}
                                        onChange={handlePostalSuburbChange}
                                        helperText={showErrors && isDirty && !isPostalSuburbValid ? "Please provide suburb" : ""}
                                    />
                                } label="Suburb" labelPlacement='top'/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{  }}>
            <FormControlLabel control = {
                                    <TextField
                                        id="txtState"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="e.g. VIC"
                                        required
                                        inputProps={{
                                            maxLength: 60
                                          }}
                                        value={postalState}
                                        disabled={disablePostalState}
                                        onChange={handlePostalStateChange}
                                        helperText={showErrors && isDirty && !isPostalStateValid ? "Please provide state" : ""}
                                    />
                                } label="State" labelPlacement='top'/>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{  }}>
            <FormControlLabel control = {
                                    <TextField
                                        id="txtPostcode"
                                        InputLabelProps={{ shrink: true }}
                                        variant="outlined"
                                        placeholder="e.g. 3185"
                                        type="text"
                                        required                                        
                                        inputProps={{
                                            maxLength: 4,
                                            //type: 'number'
                                          }}
                                        value={postalPostCode}
                                        disabled={disablePostalPostCode}
                                        onChange={handlePostalPostCodeChange}
                                        onKeyDown={handlePostalPostCodeKeyDown}
                                        helperText={showErrors && isDirty && !isPostalPostCodeValid ? "Please provide postcode" : ""}
                                    />
                                } label="Postcode" labelPlacement='top'/>
            </Box>
          </Grid>
        </Grid>
        {/* </StyleRoot> */}
      </Box>
    );
  });
  
  export default AustralianPostalAddress;
