// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  margin-left: 0;
  padding-left: 1em;
}

ul li {
  list-style: none;  
  padding-left: 10px;
  padding-top: 6px;
  text-indent: 0em;
  vertical-align: top;
  display: list-item;
}

ul li:before {
  content: "";
  color: #168625;
  padding-right: 0.5em;
  margin:0;
  font-size: 1em;
  vertical-align: top;
  font-family: 'Poppins,Roboto,Arial,sans-serif'
}

ul li.white-ul:before {
  color: white;
}

.helperTextError {
  font-size: '18px';
  font-family: 'Poppins,Roboto,Arial,sans-serif';
  color: '#A30134'
}

.MuiButton:hover {
  background-color: '#f44336 !important';
  color: '#ffffff !important';
}

.pac-container:after{
  content:none !important;
}

.redColorText {
  color: '#e00';
  font-size: '16px !important';
}

.helperTextError {
  color: '#A30134'
}

a {
  color: #86162C;
  text-decoration: underline;
}

a:hover, a:focus {
  color: #86162C;
  text-decoration: underline;
}

a:focus {
  outline: #86162C solid thin;
}`, "",{"version":3,"sources":["webpack://./static/css/ora-app.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,cAAc;EACd,oBAAoB;EACpB,QAAQ;EACR,cAAc;EACd,mBAAmB;EACnB;AACF;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,8CAA8C;EAC9C;AACF;;AAEA;EACE,sCAAsC;EACtC,2BAA2B;AAC7B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,4BAA4B;AAC9B;;AAEA;EACE;AACF;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,0BAA0B;AAC5B;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["ul {\n  margin-left: 0;\n  padding-left: 1em;\n}\n\nul li {\n  list-style: none;  \n  padding-left: 10px;\n  padding-top: 6px;\n  text-indent: 0em;\n  vertical-align: top;\n  display: list-item;\n}\n\nul li:before {\n  content: \"\";\n  color: #168625;\n  padding-right: 0.5em;\n  margin:0;\n  font-size: 1em;\n  vertical-align: top;\n  font-family: 'Poppins,Roboto,Arial,sans-serif'\n}\n\nul li.white-ul:before {\n  color: white;\n}\n\n.helperTextError {\n  font-size: '18px';\n  font-family: 'Poppins,Roboto,Arial,sans-serif';\n  color: '#A30134'\n}\n\n.MuiButton:hover {\n  background-color: '#f44336 !important';\n  color: '#ffffff !important';\n}\n\n.pac-container:after{\n  content:none !important;\n}\n\n.redColorText {\n  color: '#e00';\n  font-size: '16px !important';\n}\n\n.helperTextError {\n  color: '#A30134'\n}\n\na {\n  color: #86162C;\n  text-decoration: underline;\n}\n\na:hover, a:focus {\n  color: #86162C;\n  text-decoration: underline;\n}\n\na:focus {\n  outline: #86162C solid thin;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
