export enum ElectionCategory {
  Parliamentary = 1,
  Municipal,
  NonGovernment,
  Unset
}

export enum ElectionType {
  GeneralElection = 1,
  ByElection,
  CommercialElections,
  Poll,
  Referendum,
  Unset
}

export enum ElectionMethodType {
  Unknown,
  Attendance,
  Postal,
  Unset
}
